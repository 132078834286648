import { APIService } from './../API.service';
import { UserType } from './../types/user';
import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import {Router} from "@angular/router";

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  links : {
    name: string, 
    link: string, 
    fragment: string,
    internal: boolean 
  }[];
  
  setSignoutLinks() {
  this.links = 
  [
    { name: 'Percive', link: '/percive', fragment: "percieve", internal: true},
    { name: 'Data Sources', link: '/percive', fragment: "datasources", internal: true},
    { name: 'Pricing', link: '/percive', fragment: "#pricing",internal: true},
    { name: 'Absenses', link: '/percive', fragment: "#absenses", internal: true}
  ];
  }

  setSignedInLinks()
  {
    this.links = [
      { name: 'Dashboard', link: '/dashboard', fragment: '', internal: false},
      { name: 'My Topics', link: '/mytopics', fragment: '', internal: false},
      { name: 'MeasureSense', link: '/measuresense', fragment: '', internal: false},
      ]
  }

  user: UserType;

  constructor( private userService: UserService,
               private router: Router,
               private API: APIService ) { 
      this.user = userService.user(); 
      console.log(this.user);
  }

  ngOnInit() {
    if (this.user && this.user.name.length > 0)
    {
      this.setSignedInLinks();
    }
    else
    {
      this.setSignoutLinks();
    }

  }

  goto(item)
  {
    console.log('Routing to: ' +item)
    this.router.navigate(item.link);
  }

  login(username, password){
    
    // Retrieve active Google user session
    this.userService.login(username, password)
      .then(data => {
  
        this.API.ListSettings().then( settings =>
          {
            const user = settings && settings.items.length > 0 ? settings.items[0]: null;
            if (!user)
            {
              this.userService.createSetting(username);
            }
            console.log('My user settings', user);
          });
        this.setSignedInLinks();
        this.router.navigate(['dashboard']);

      console.log(data)
    })
    .catch(err => {
      //this.message = err;
    
      console.log(err)
    });
  }


  signout()
  {
    this.userService.signout()
      .then( result => {
        if (result === true)
        {
          this.setSignoutLinks();
          this.router.navigate(['percive']); 
        }
      });
  }
}
