import { APIService } from './../API.service';
import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-percive',
  templateUrl: './percive2.component.html',
  styleUrls: ['./percive.component.css']
})
export class PerciveComponent implements OnInit {
  translations: any;
  username: String = '';
  password: String = '';
  message: String = '';

  constructor(
    private router: Router,
    private user: UserService,
    private API: APIService) {}

  ngOnInit() {
   /* this.translations = {
      percive: {
        introduction: `Percive is a web tool to measure the heat of the buzz your favorite topics
      generate on the Internet. It does not matter if you care about the splash of your brand makes
       with its latest product, or your die-hard contestant in that cool reality show; the changes 
       in the sentiments of the audience is visible at your fingertips. Use this handy tool from 
       your mobile or your desktop to follow trends and learn about people and their feelings about
        what you care. Because with Percieve, you can measure how it is perceived.`,
        title_datasources: 'Data Sources',
        datasources: `Internet discussion happens at several channels, depending on the nature and geography
       of the topics you are focused on. Percive offers you a number of options where do you want to measure
        sentiments. Certainly, you can choose a different set of sources for all your specific topics .
      Twitter – Quick discussions about most topics with a general interest. Suitable for measuring any kind of topics.
      Internet forums – Best if there are thematic sites available to your topic of interest. 
      Localized discussion portals, including Suomi24 – Best if your topics are mostly followed in a 
      specific language where discussions are mostly happening at these portals.
      `,
        title_reports: 'Reports',
        reports: `Do you want to incorporate Percive sentiments into your work? Do you need status 
      reports about a large range of topics every Monday morning? Automated scheduled reports are 
      available for you to take full advantage of the strength of Percive and never miss formulating trends in Internet discussions.`,
        title_alerts: 'Alerts',
        alerts: `Are sentiments vital for your own brand or service? Yes, they are! Do you want to miss the
       developing trends of positive or negative feelings related to your favorite TV show candidate? Of
        course, not! With Percive alerts you can catch the forming barrel early to have an awesome ride of
         the top of the wave of positive feelings. Never miss a good hype or a crunchy PR disaster with Percive
          and its email alerts at your service!`,
        title_pricing: 'Pricing',
        pricing: ``,

      registerForFree: 'Register for Free'
      }
    };
  }*/

  this.translations = {
    percive: {
      introduction: `Percive on moderni online työkalu sinua kiinnostavien keskustelujen seuraamiseen. 
      Voit seurata tuoreen tuotelanseerauksen vaikutusta brändiin tai vaikka mielipiteitä reality 
      ohjelman osallistujista. Voit käsin koskettaa yleisösi tunteita reaaliajassa. Tämä kätevä 
      työkalu toimii mobiilisti ja isolta ruudulta.  Seuraa trendejä ja opi ihmisistä ja heidän 
      tunteista sinua kiinnostavista aihepiireistä. Percievellä voit havainnoida, kuinka sinut 
      koetaan.
      `,
      title_datasources: 'Tietolähteet',
      datasources: `Internet keskustelut ovat monikanavaisia riippuen keskustelujen luonteesta ja 
      aihepiireistä. Percive tarjoaa useita mahdollisuuksia keskustelujen tunteiden seuraamiseen. Voit 
      valita sopivia kanavia erilaisten keskustelujen seuraamiseen.

      Twitter - Nopeat keskustelut yleisimmistä aiheista. Soveltuu minkä tahansa aihepiirin seuraamiseen.
      
      Internet foorumit - Toimii parhaiten jos aihepiirillä on oma fooruminsa. 
      
      Paikalliset foorumit, mukaan lukien Suomi 24 - Erinomainen kun keskustelut tapahtuvat paikallisella
      kielellä keskittyen kyseiseen keskustelufoorumiin.
      
    `,
      title_reports: 'Raportit',
      reports: `Tarvitsetko Percieve tunneanalyysejä työsi tekemiseen? Tarvitsetko säännöllisiä 
      tilanneraportteja useista aihealueista joka maanantai aamu? Automatisoiduilla raporteilla 
      voit hyödyntää Percieve kokonaisvaltaisesti, etkä ikinä missaa muodostuvaa trendiä Internet 
      keskusteluissa.`,
      title_alerts: 'Hälytykset',
      alerts: `Ovatko tunteet brändistäsi tai palvelustasi elintärkeitä liiketoiminnallesi? Kyllä, ne ovat! 
      Haluatko jättää huomiotta positiiviset tai negatiiviset tunteet sinun lempi TV- show kilpailijasta?
      Et tietenkään! Percive hälytyksillä pääset heti aallon harjalle ja voit hyödyntää positiiviset
      tunteet. Älä missaa hyvää hypeä tai ala heti käsittelemään negatiivisia tunteita Percieven avulla.`,
      title_pricing: 'Tilausvaihtoehdot',
      pricing: ``,

      registerForFree: 'Rekisteröidy ilmaiseksi!',
      register: 'Rekisteröidy',
      login: 'Kirjaudu'
    }
  };
}
  
  login(username, password) {

    // Retrieve active Google user session
    this.user.login(username, password)
      .then(data => {
        this.API.ListSettings().then( settings => {
            const user = settings && settings.items.length > 0 ? settings.items[0] : null;
            if (!user) {
              this.user.createSetting(username);
            }
            console.log('My user settings', user);
          });
        this.router.navigate(['menu']);

      console.log(data);
    })
    .catch(err => {
      this.message = err;

      console.log(err);
    });
  }

}
