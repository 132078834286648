import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const ROOT_URL = 'https://rqtzlnhe85.execute-api.eu-central-1.amazonaws.com/dev/percive-scorer-f';
//const PERCIVE_URL = 'http://localhost:3000';
const PERCIVE_URL = 'https://t3yh5nf3ue.execute-api.eu-central-1.amazonaws.com/Prod';
@Injectable({
  providedIn: 'root'
})

export class MeasureSenseService {

  constructor(private http: HttpClient) { }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  fetchTopicsStatics(topic: string) {
      const storedToken = localStorage.getItem('idToken');
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': storedToken
          // 'X-API-KEY': 'HelloAnnetteThisIsYourSecretKey'
          // 'Content-Type':  'application/json',
          // 'Authorization': 'my-auth-token'
        })
    };

    const url = `${PERCIVE_URL}/report?topic=${topic}`;
    console.log(url);
    return this.http.get(url, httpOptions)
      .pipe(
        catchError(this.handleError));

  }

  analyze(inputs: { text: String }[]): any {
    /* const axiosParams =
     {
         method: 'POST',
         url: `${ROOT_URL}`,
         headers: { 'X-API-KEY': 'HelloAnnetteThisIsYourSecretKey' },
         data:  { "textarray": inputs }
     };*/
    const storedToken = localStorage.getItem('idToken');
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': storedToken
        // 'X-API-KEY': 'HelloAnnetteThisIsYourSecretKey'
        // 'Content-Type':  'application/json',
        // 'Authorization': 'my-auth-token'
      })
    };

    // var promise = new Promise( (resolve, reject) =>
    // {
    const url = `${ROOT_URL}`;
    const body = { 'textarray': inputs };
    console.log(url);
    console.log(JSON.stringify(body));

    return this.http.post(url, body, httpOptions)
      .pipe(
        catchError(this.handleError));

  }
}
