/* tslint:disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import * as Observable from "zen-observable";

export type CreateSettingInput = {
  id?: string | null;
  phone?: string | null;
  email: string;
};

export type UpdateSettingInput = {
  id: string;
  phone?: string | null;
  email?: string | null;
};

export type DeleteSettingInput = {
  id?: string | null;
};

export type CreateChannelSourceInput = {
  id?: string | null;
  name: string;
};

export type UpdateChannelSourceInput = {
  id: string;
  name?: string | null;
};

export type DeleteChannelSourceInput = {
  id?: string | null;
};

export type CreateTopicInput = {
  id?: string | null;
  name: string;
  keys?: Array<KeywordInput> | null;
  language?: string | null;
  channels?: Array<string> | null;
  alarm?: AlarmInput | null;
  isActive: boolean;
};

export type KeywordInput = {
  name: string;
  terms?: Array<string> | null;
};

export type AlarmInput = {
  isActive?: boolean | null;
  thresholdHigh?: number | null;
  thresholdLow?: number | null;
  reportEmail: boolean;
  reportSMS: boolean;
};

export type UpdateTopicInput = {
  id: string;
  name?: string | null;
  keys?: Array<KeywordInput> | null;
  language?: string | null;
  channels?: Array<string> | null;
  alarm?: AlarmInput | null;
  isActive?: boolean | null;
};

export type DeleteTopicInput = {
  id?: string | null;
};

export type ModelSettingFilterInput = {
  id?: ModelIDFilterInput | null;
  phone?: ModelStringFilterInput | null;
  email?: ModelStringFilterInput | null;
  and?: Array<ModelSettingFilterInput | null> | null;
  or?: Array<ModelSettingFilterInput | null> | null;
  not?: ModelSettingFilterInput | null;
};

export type ModelIDFilterInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelStringFilterInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelChannelSourceFilterInput = {
  id?: ModelIDFilterInput | null;
  name?: ModelStringFilterInput | null;
  and?: Array<ModelChannelSourceFilterInput | null> | null;
  or?: Array<ModelChannelSourceFilterInput | null> | null;
  not?: ModelChannelSourceFilterInput | null;
};

export type ModelTopicFilterInput = {
  id?: ModelIDFilterInput | null;
  name?: ModelStringFilterInput | null;
  language?: ModelStringFilterInput | null;
  channels?: ModelStringFilterInput | null;
  isActive?: ModelBooleanFilterInput | null;
  and?: Array<ModelTopicFilterInput | null> | null;
  or?: Array<ModelTopicFilterInput | null> | null;
  not?: ModelTopicFilterInput | null;
};

export type ModelBooleanFilterInput = {
  ne?: boolean | null;
  eq?: boolean | null;
};

export type CreateSettingMutation = {
  __typename: string;
  id: string | null;
  phone: string | null;
  email: string;
};

export type UpdateSettingMutation = {
  __typename: string;
  id: string | null;
  phone: string | null;
  email: string;
};

export type DeleteSettingMutation = {
  __typename: string;
  id: string | null;
  phone: string | null;
  email: string;
};

export type CreateChannelSourceMutation = {
  __typename: string;
  id: string;
  name: string;
};

export type UpdateChannelSourceMutation = {
  __typename: string;
  id: string;
  name: string;
};

export type DeleteChannelSourceMutation = {
  __typename: string;
  id: string;
  name: string;
};

export type CreateTopicMutation = {
  __typename: string;
  id: string;
  name: string;
  keys: Array<{
    __typename: "Keyword";
    name: string;
    terms: Array<string> | null;
  }> | null;
  language: string | null;
  channels: Array<string> | null;
  alarm: {
    __typename: "Alarm";
    isActive: boolean | null;
    thresholdHigh: number | null;
    thresholdLow: number | null;
    reportEmail: boolean;
    reportSMS: boolean;
  } | null;
  isActive: boolean;
};

export type UpdateTopicMutation = {
  __typename: string;
  id: string;
  name: string;
  keys: Array<{
    __typename: "Keyword";
    name: string;
    terms: Array<string> | null;
  }> | null;
  language: string | null;
  channels: Array<string> | null;
  alarm: {
    __typename: "Alarm";
    isActive: boolean | null;
    thresholdHigh: number | null;
    thresholdLow: number | null;
    reportEmail: boolean;
    reportSMS: boolean;
  } | null;
  isActive: boolean;
};

export type DeleteTopicMutation = {
  __typename: string;
  id: string;
  name: string;
  keys: Array<{
    __typename: "Keyword";
    name: string;
    terms: Array<string> | null;
  }> | null;
  language: string | null;
  channels: Array<string> | null;
  alarm: {
    __typename: "Alarm";
    isActive: boolean | null;
    thresholdHigh: number | null;
    thresholdLow: number | null;
    reportEmail: boolean;
    reportSMS: boolean;
  } | null;
  isActive: boolean;
};

export type GetSettingQuery = {
  __typename: string;
  id: string | null;
  phone: string | null;
  email: string;
};

export type ListSettingsQuery = {
  __typename: string;
  items: Array<{
    __typename: "Setting";
    id: string | null;
    phone: string | null;
    email: string;
  } | null> | null;
  nextToken: string | null;
};

export type GetChannelSourceQuery = {
  __typename: string;
  id: string;
  name: string;
};

export type ListChannelSourcesQuery = {
  __typename: string;
  items: Array<{
    __typename: "ChannelSource";
    id: string;
    name: string;
  } | null> | null;
  nextToken: string | null;
};

export type GetTopicQuery = {
  __typename: string;
  id: string;
  name: string;
  keys: Array<{
    __typename: "Keyword";
    name: string;
    terms: Array<string> | null;
  }> | null;
  language: string | null;
  channels: Array<string> | null;
  alarm: {
    __typename: "Alarm";
    isActive: boolean | null;
    thresholdHigh: number | null;
    thresholdLow: number | null;
    reportEmail: boolean;
    reportSMS: boolean;
  } | null;
  isActive: boolean;
};

export type ListTopicsQuery = {
  __typename: string;
  items: Array<{
    __typename: "Topic";
    id: string;
    name: string;
    keys: Array<{
      __typename: "Keyword";
      name: string;
      terms: Array<string> | null;
    }> | null;
    language: string | null;
    channels: Array<string> | null;
    alarm: {
      __typename: "Alarm";
      isActive: boolean | null;
      thresholdHigh: number | null;
      thresholdLow: number | null;
      reportEmail: boolean;
      reportSMS: boolean;
    } | null;
    isActive: boolean;
  } | null> | null;
  nextToken: string | null;
};

export type OnCreateSettingSubscription = {
  __typename: string;
  id: string | null;
  phone: string | null;
  email: string;
};

export type OnUpdateSettingSubscription = {
  __typename: string;
  id: string | null;
  phone: string | null;
  email: string;
};

export type OnDeleteSettingSubscription = {
  __typename: string;
  id: string | null;
  phone: string | null;
  email: string;
};

export type OnCreateChannelSourceSubscription = {
  __typename: string;
  id: string;
  name: string;
};

export type OnUpdateChannelSourceSubscription = {
  __typename: string;
  id: string;
  name: string;
};

export type OnDeleteChannelSourceSubscription = {
  __typename: string;
  id: string;
  name: string;
};

export type OnCreateTopicSubscription = {
  __typename: string;
  id: string;
  name: string;
  keys: Array<{
    __typename: "Keyword";
    name: string;
    terms: Array<string> | null;
  }> | null;
  language: string | null;
  channels: Array<string> | null;
  alarm: {
    __typename: "Alarm";
    isActive: boolean | null;
    thresholdHigh: number | null;
    thresholdLow: number | null;
    reportEmail: boolean;
    reportSMS: boolean;
  } | null;
  isActive: boolean;
};

export type OnUpdateTopicSubscription = {
  __typename: string;
  id: string;
  name: string;
  keys: Array<{
    __typename: "Keyword";
    name: string;
    terms: Array<string> | null;
  }> | null;
  language: string | null;
  channels: Array<string> | null;
  alarm: {
    __typename: "Alarm";
    isActive: boolean | null;
    thresholdHigh: number | null;
    thresholdLow: number | null;
    reportEmail: boolean;
    reportSMS: boolean;
  } | null;
  isActive: boolean;
};

export type OnDeleteTopicSubscription = {
  __typename: string;
  id: string;
  name: string;
  keys: Array<{
    __typename: "Keyword";
    name: string;
    terms: Array<string> | null;
  }> | null;
  language: string | null;
  channels: Array<string> | null;
  alarm: {
    __typename: "Alarm";
    isActive: boolean | null;
    thresholdHigh: number | null;
    thresholdLow: number | null;
    reportEmail: boolean;
    reportSMS: boolean;
  } | null;
  isActive: boolean;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateSetting(
    input: CreateSettingInput
  ): Promise<CreateSettingMutation> {
    const statement = `mutation CreateSetting($input: CreateSettingInput!) {
        createSetting(input: $input) {
          __typename
          id
          phone
          email
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSettingMutation>response.data.createSetting;
  }
  async UpdateSetting(
    input: UpdateSettingInput
  ): Promise<UpdateSettingMutation> {
    const statement = `mutation UpdateSetting($input: UpdateSettingInput!) {
        updateSetting(input: $input) {
          __typename
          id
          phone
          email
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSettingMutation>response.data.updateSetting;
  }
  async DeleteSetting(
    input: DeleteSettingInput
  ): Promise<DeleteSettingMutation> {
    const statement = `mutation DeleteSetting($input: DeleteSettingInput!) {
        deleteSetting(input: $input) {
          __typename
          id
          phone
          email
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSettingMutation>response.data.deleteSetting;
  }
  async CreateChannelSource(
    input: CreateChannelSourceInput
  ): Promise<CreateChannelSourceMutation> {
    const statement = `mutation CreateChannelSource($input: CreateChannelSourceInput!) {
        createChannelSource(input: $input) {
          __typename
          id
          name
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateChannelSourceMutation>response.data.createChannelSource;
  }
  async UpdateChannelSource(
    input: UpdateChannelSourceInput
  ): Promise<UpdateChannelSourceMutation> {
    const statement = `mutation UpdateChannelSource($input: UpdateChannelSourceInput!) {
        updateChannelSource(input: $input) {
          __typename
          id
          name
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateChannelSourceMutation>response.data.updateChannelSource;
  }
  async DeleteChannelSource(
    input: DeleteChannelSourceInput
  ): Promise<DeleteChannelSourceMutation> {
    const statement = `mutation DeleteChannelSource($input: DeleteChannelSourceInput!) {
        deleteChannelSource(input: $input) {
          __typename
          id
          name
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteChannelSourceMutation>response.data.deleteChannelSource;
  }
  async CreateTopic(input: CreateTopicInput): Promise<CreateTopicMutation> {
    const statement = `mutation CreateTopic($input: CreateTopicInput!) {
        createTopic(input: $input) {
          __typename
          id
          name
          keys {
            __typename
            name
            terms
          }
          language
          channels
          alarm {
            __typename
            isActive
            thresholdHigh
            thresholdLow
            reportEmail
            reportSMS
          }
          isActive
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTopicMutation>response.data.createTopic;
  }
  async UpdateTopic(input: UpdateTopicInput): Promise<UpdateTopicMutation> {
    const statement = `mutation UpdateTopic($input: UpdateTopicInput!) {
        updateTopic(input: $input) {
          __typename
          id
          name
          keys {
            __typename
            name
            terms
          }
          language
          channels
          alarm {
            __typename
            isActive
            thresholdHigh
            thresholdLow
            reportEmail
            reportSMS
          }
          isActive
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTopicMutation>response.data.updateTopic;
  }
  async DeleteTopic(input: DeleteTopicInput): Promise<DeleteTopicMutation> {
    const statement = `mutation DeleteTopic($input: DeleteTopicInput!) {
        deleteTopic(input: $input) {
          __typename
          id
          name
          keys {
            __typename
            name
            terms
          }
          language
          channels
          alarm {
            __typename
            isActive
            thresholdHigh
            thresholdLow
            reportEmail
            reportSMS
          }
          isActive
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTopicMutation>response.data.deleteTopic;
  }
  async GetSetting(id: string): Promise<GetSettingQuery> {
    const statement = `query GetSetting($id: ID!) {
        getSetting(id: $id) {
          __typename
          id
          phone
          email
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSettingQuery>response.data.getSetting;
  }
  async ListSettings(
    filter?: ModelSettingFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSettingsQuery> {
    const statement = `query ListSettings($filter: ModelSettingFilterInput, $limit: Int, $nextToken: String) {
        listSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            phone
            email
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSettingsQuery>response.data.listSettings;
  }
  async GetChannelSource(id: string): Promise<GetChannelSourceQuery> {
    const statement = `query GetChannelSource($id: ID!) {
        getChannelSource(id: $id) {
          __typename
          id
          name
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetChannelSourceQuery>response.data.getChannelSource;
  }
  async ListChannelSources(
    filter?: ModelChannelSourceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListChannelSourcesQuery> {
    const statement = `query ListChannelSources($filter: ModelChannelSourceFilterInput, $limit: Int, $nextToken: String) {
        listChannelSources(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListChannelSourcesQuery>response.data.listChannelSources;
  }
  async GetTopic(id: string): Promise<GetTopicQuery> {
    const statement = `query GetTopic($id: ID!) {
        getTopic(id: $id) {
          __typename
          id
          name
          keys {
            __typename
            name
            terms
          }
          language
          channels
          alarm {
            __typename
            isActive
            thresholdHigh
            thresholdLow
            reportEmail
            reportSMS
          }
          isActive
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTopicQuery>response.data.getTopic;
  }
  async ListTopics(
    filter?: ModelTopicFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTopicsQuery> {
    const statement = `query ListTopics($filter: ModelTopicFilterInput, $limit: Int, $nextToken: String) {
        listTopics(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            keys {
              __typename
              name
              terms
            }
            language
            channels
            alarm {
              __typename
              isActive
              thresholdHigh
              thresholdLow
              reportEmail
              reportSMS
            }
            isActive
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTopicsQuery>response.data.listTopics;
  }
  OnCreateSettingListener: Observable<
    OnCreateSettingSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateSetting {
        onCreateSetting {
          __typename
          id
          phone
          email
        }
      }`
    )
  ) as Observable<OnCreateSettingSubscription>;

  OnUpdateSettingListener: Observable<
    OnUpdateSettingSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateSetting {
        onUpdateSetting {
          __typename
          id
          phone
          email
        }
      }`
    )
  ) as Observable<OnUpdateSettingSubscription>;

  OnDeleteSettingListener: Observable<
    OnDeleteSettingSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteSetting {
        onDeleteSetting {
          __typename
          id
          phone
          email
        }
      }`
    )
  ) as Observable<OnDeleteSettingSubscription>;

  OnCreateChannelSourceListener: Observable<
    OnCreateChannelSourceSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateChannelSource {
        onCreateChannelSource {
          __typename
          id
          name
        }
      }`
    )
  ) as Observable<OnCreateChannelSourceSubscription>;

  OnUpdateChannelSourceListener: Observable<
    OnUpdateChannelSourceSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateChannelSource {
        onUpdateChannelSource {
          __typename
          id
          name
        }
      }`
    )
  ) as Observable<OnUpdateChannelSourceSubscription>;

  OnDeleteChannelSourceListener: Observable<
    OnDeleteChannelSourceSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteChannelSource {
        onDeleteChannelSource {
          __typename
          id
          name
        }
      }`
    )
  ) as Observable<OnDeleteChannelSourceSubscription>;

  OnCreateTopicListener: Observable<OnCreateTopicSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnCreateTopic {
        onCreateTopic {
          __typename
          id
          name
          keys {
            __typename
            name
            terms
          }
          language
          channels
          alarm {
            __typename
            isActive
            thresholdHigh
            thresholdLow
            reportEmail
            reportSMS
          }
          isActive
        }
      }`
    )
  ) as Observable<OnCreateTopicSubscription>;

  OnUpdateTopicListener: Observable<OnUpdateTopicSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateTopic {
        onUpdateTopic {
          __typename
          id
          name
          keys {
            __typename
            name
            terms
          }
          language
          channels
          alarm {
            __typename
            isActive
            thresholdHigh
            thresholdLow
            reportEmail
            reportSMS
          }
          isActive
        }
      }`
    )
  ) as Observable<OnUpdateTopicSubscription>;

  OnDeleteTopicListener: Observable<OnDeleteTopicSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteTopic {
        onDeleteTopic {
          __typename
          id
          name
          keys {
            __typename
            name
            terms
          }
          language
          channels
          alarm {
            __typename
            isActive
            thresholdHigh
            thresholdLow
            reportEmail
            reportSMS
          }
          isActive
        }
      }`
    )
  ) as Observable<OnDeleteTopicSubscription>;
}
