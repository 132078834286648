import { CreateSettingInput } from './../API.service';
import { UserType } from '../types/user';
import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { APIService } from '../API.service';
import { Cache } from 'aws-amplify';


import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  data : UserType = { name: '', email: ''}

  constructor( 
    private API : APIService
    ) { }

  user() : UserType
  {

    const data = localStorage.getItem('user');
    if (data)
    {
      try
      {
        const parsedUsed = JSON.parse(data);
        return parsedUsed;
      }
      catch(exp)
      {
        console.log('Data parsing failed');
      }

    }
    return null;
  }

  login(username, password) {
    var promise = new Promise((resolve, reject) => {
      
      Auth.signIn(username, password)
        .then(response => {
          // user data
          console.log(response);
          this.data.name = response.username;
          
          let token = response.signInUserSession.idToken.jwtToken;
          localStorage.setItem('user', JSON.stringify(this.data));
          localStorage.setItem('idToken', token);
          resolve(this.data);
        })
        .catch(err => {
          reject(err);
          console.log(err)
        });
    }
    );
    return promise;
  }

  createSetting( username: string )
  {
    let settings : CreateSettingInput =
    {
      email: username
    }
    this.API.CreateSetting(settings).then(results => 
      {
        console.log('Settings created result: ', results);
      });
  }

  googleLogin() {
    var promise = new Promise((resolve, reject) => {
      const gapi = window['gapi'];
      let self = this;
          
      gapi.load('client:auth2', {
        callback: function() {
          // Handle gapi.client initialization.
          gapi.client.init({
            apiKey:	'6FcXJbW7cJV2N3zH1NCD9MzW',
            discoveryDocs:	[],
            clientId: '1062012828794-6ubn7sriojrcjal91v60g9l6cd6vpi5i.apps.googleusercontent.com', //The app's client ID, found and created in the Google Developers Console.
            scope: 'https://www.googleapis.com/auth/userinfo.profile' //	The scopes to request, as a space-delimited string.
          });
          
          const ga = gapi.auth2.getAuthInstance();
          ga.signIn().then(googleUser => {
              const { id_token, expires_at } = googleUser.getAuthResponse();
              const profile = googleUser.getBasicProfile();
              self.data = {
                  email: profile.getEmail(),
                  name: profile.getName()
              };
              let user = { email: profile.getEmail(),
                name: profile.getName()};
              localStorage.setItem('user', JSON.stringify(self.data));
          
              return Auth.federatedSignIn(
                  // Initiate federated sign-in with Google identity provider 
                  'google',
                  { 
                      // the JWT token
                      token: id_token, 
                      // the expiration time
                      expires_at 
                  },
                  // a user object
                  user
              ).then(response => {
                // user data

                // token for FEDERATED login in web
                const federatedInfo = Cache.getItem('federatedInfo');
                const { token } = federatedInfo;
                

                console.log(response);
                //let token = response.sessionToken;
                localStorage.setItem('idToken', token);
                resolve(self.data);
              });
          });
        },
        onerror: function() {
          // Handle loading error.
          alert('gapi.client failed to load!');
        },
        timeout: 5000, // 5 seconds.
        ontimeout: function() {
          // Handle timeout.
          alert('gapi.client could not load in a timely manner!');
        }
      });
 
    }
    );
    return promise;
  }

  signout()
  {
    var promise = new Promise((resolve, reject) => {

    Auth.signOut({ global: true })
      .then(data => {
        console.log(data);
        localStorage.removeItem('user');
        resolve(true);
      })
      .catch(err => {
        console.log(err)
        resolve(false);
      });
      });
    return promise;
  }
}
