import { APIService } from '../API.service';
import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserService } from '../services/user.service';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
// new user registered
// {"CodeDeliveryDetails":{"AttributeName":"email","DeliveryMedium":"EMAIL","Destination":
// "t***@c***.com"},"UserConfirmed":false,"UserSub":"a7ccbaed-4306-4508-9754-890f170fea75"}

// Try to register, but unconfirmed account exists
// {"__type":"UsernameExistsException","message":"User already exists"}

export class SignInComponent implements OnInit {

  username: String = ''; // tero.vk@gmail.com';
  password: String = ''; // 'T#ero1234';
  firstname: String = '';
  lastname: String = '';

  visible = 'login';
  title = 'Login';

  message: String = '';

  constructor(
    private router: Router,
    private user: UserService,
    private API: APIService ) { }

  ngOnInit() {
  }

  login(username, password) {

    // Retrieve active Google user session
    this.user.login(username, password)
      .then(data => {
        this.API.ListSettings().then( settings => {
            const user = settings && settings.items.length > 0 ? settings.items[0] : null;
            if (!user) {
              this.user.createSetting(username);
            }
            console.log('My user settings', user);
          });
        this.router.navigate(['menu']);

      console.log(data);
    })
    .catch(err => {
      this.message = err;

      console.log(err);
    });
  }


  googleLogin() {
    // Retrieve active Google user session
    this.user.googleLogin()
      .then(data => {
      // this.message = data.message;
      this.router.navigate(['menu']);

      console.log(data);
    })
    .catch(err => {
      this.message = err.message;

      console.log(err);
    });
  }

  register(username, firstname, lastname, password) {
    Auth.signUp({
        username,
        password,
        attributes: {
            email: username,          // optional
            family_name: lastname,
            given_name: firstname,
    //       phone_number,   // optional - E.164 number convention
            // other custom attributes
        },
      // validationData: []  //optional
        })
        .then(data => {
          this.message = 'Check you email for the confirm pin code';
          this.visible = 'confirm';
          console.log(data);

        })
        .catch(err => {
          this.message = err.message;
          console.log(err);
        });
  }

  confirmPin(username, pincode) {

    // After retrieveing the confirmation code from the user
    Auth.confirmSignUp(username, pincode, {
        // Optional. Force user confirmation irrespective of existing alias. By default set to True.
        forceAliasCreation: false
    }).then(data => {
      // this.message = data.message;
      console.log(data);
      this.user.createSetting(username);

      this.router.navigate(['menu']);
    })
    .catch(err => {
      this.message = err.message;
      console.log(err);
    });
    }
}
