import { MeasureSenseService } from '../services/measure-sense.service';
import { Component, OnInit } from '@angular/core';
import _ from 'lodash';

@Component({
  selector: 'text-analysis',
  templateUrl: './text-analysis.component.html',
  styleUrls: ['./text-analysis.component.css']
})
export class TextAnalysisComponent implements OnInit {

  inputs :  { text: String }[] = [];

  output : any;

  constructor(private service: MeasureSenseService) { 

  }
  

  addInput( text: String)
  {
    this.inputs.push({ text });
  }

  analyze()
  {
    this.service.analyze(this.inputs)
    .subscribe((data: any) => {
      console.log(data);
      var copy = _.map(data.resultArray, _.clone);

      // flatten the structure
      let flatten = copy.map( function(item) {
         var sentiments = [];
         for(var i = 0; i < item.results.stems.length ; i++)
         {
             sentiments.push(
                 {
                     'stem': item.results.stems[i],
                     'sentiment': item.results.sentiment[i]
                 });
         }
         
         return {
             id: item.textId,
             text: item.results.cleantext,
             sentiments: sentiments,
             cumsent: item.results.cumsent
         };
     }); 
     this.output = flatten;

    });
  }

  removeInput( index: Number )
  {
    // TODO
  }

  clear()
  {
    this.inputs = [];
    this.addInput('');
    delete this.output; 
  }

  ngOnInit() {
    this.addInput( 'Kun on tullut silloin tällöin nokiteltua #vihreät :eitä, piti jo tasapainon vuoksi ruveta tekemään #nyhtökaura :sta maronilaatikkoa. Ainakin tämä tuoksui hyvältä. Varmaan vaikuttaa huomenna ainakin mieliala #ilmasto :on.');
    this.addInput('Kieltämättä oli aika hassua, että yli vuoden sinkkuuden suomi24 treffit -palstan jäsenyyden jälkeen haksahdin kuvattomaan profiiliin. Ja tuossa tuo nyt on ollut kohta 8 vuotta. Mä näistä tindereistä mitään tajua enää.');
    this.addInput('Liikkeessä yli rajojen -blogissa Emma Nortion kiinnostavaa pohdintaa siitä, miten nettikirjoittelu eroaa kasvokkaisesta vuorovaikutuksesta ja millaista on Suomi24-foorumilla käyty keskustelu monikulttuurisuudesta');
  
  }

}
