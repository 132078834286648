export const DEFAULT_TOPIC_LANGUAGE = 'fi';

export const SUPPORTED_LANGUAGES =  ['fi', 'en'];

// http://paletton.com/#uid=13L0u0kg0A-6bUpbiLqkatPosqi
export const color_primary_0 = { color: '#6680B9' };
export const color_primary_1 = { color: '#ffD1EE' };
export const color_primary_2 = { color: '#92ddD6' };
export const color_primary_3 = { color: '#45619F' };
export const color_primary_4 = { color: '#2C498C' };
/*
.color-primary-0 { color: #42B25F }	
.color-primary-1 { color: #C3F2CF }
.color-primary-2 { color: #91E0A6 }
.color-primary-3 { color: #42B25F }
.color-primary-4 { color: #259D44 }
*/

export const THEME_COLORS = [
    color_primary_0,
    color_primary_1,
    color_primary_2,
    color_primary_3,
    color_primary_4,
];

