import { THEME_COLORS } from './../constans';
import { MeasureSenseService } from './../services/measure-sense.service';
import { CreateTopicInput } from './../API.service';

import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { templateData } from '../template_data';
import { APIService } from '../API.service';
import { TopicPlotResponse } from '../types/types';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  LineChart = [];

  topics: CreateTopicInput[] = [];

  constructor(
    private API: APIService,
    private measureSense: MeasureSenseService) {

  }

  ngOnInit() {
    this.loadTopics();
  }

  loadCharts() {
    this.topics.forEach(topic => {
      if (topic.isActive) {
        this.createChart(topic);
      }}
      );
  }

  loadTopics() {
    this.API.ListTopics().then(results => {
      this.topics = results.items;
      // this.removeProperty( this.topics, '__typename');
      // this.topics.forEach( topic => this.updateTopicChannels(topic));
      console.log(results);
    }).catch(reason => {
      console.log(reason);
    });
  }

  createChart(topic) {
    const { id, name } = topic;

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
  }

    const data = this.measureSense.fetchTopicsStatics(id) // '7da78594-2380-44d8-a594-610885480b2f')
    .subscribe(response => {
      console.log(response);
      const {keys, dates, dataSets} = response as TopicPlotResponse;
      this.LineChart = new Chart(id, {
        type: 'line',
        data: {
          labels: dates,
          datasets: dataSets.map( (set, index) => {
            return {
            label: keys[index],
            data: set,
            fill: false,
            lineTension: 0.5,
            borderColor: THEME_COLORS[index % (THEME_COLORS.length - 1)]['color'],
            backgroundColor: THEME_COLORS[index % (THEME_COLORS.length - 1)]['color'],
            borderWidth: 3
            };
          })
        },
        options:
        {
          title:
          {
            text: name,
            display: true
          },
          scales: {
            yAxes: [{
              stacked: true,
              ticks: {
                beginAtZero: true
              }
            }]
          }
        }
      });
    });


  }

}
