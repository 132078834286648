import { SUPPORTED_LANGUAGES, DEFAULT_TOPIC_LANGUAGE } from './../constans';
import { ListChannelSourcesQuery } from './../API.service';
import { APIService, CreateTopicInput, UpdateTopicInput, KeywordInput, UpdateTopicMutation, CreateTopicMutation } from '../API.service';
import { Component, OnInit } from '@angular/core';
import { Topic } from '../types/types';
import { timingSafeEqual } from 'crypto';
import { isArray, isObject } from 'util';
import { TemplateParseResult } from '@angular/compiler';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-mytopics',
  templateUrl: './mytopics.component.html',
  styleUrls: ['./mytopics.component.css']
})

export class MytopicsComponent implements OnInit {

topics: CreateTopicInput[] = [];

constructor(private API: APIService) { }

channels: { id: string, name: string } [];

languages: string[] = SUPPORTED_LANGUAGES;

switch = {
  active: true,
  color: '#red',
  disabled: false
};

  removeProperty(obj: any, name: string) {
  if (obj.hasOwnProperty(name)) {
    delete obj[name];
  }
  if (isArray(obj) && obj.length > 0) {
    obj.forEach(element => {
      this.removeProperty(element, name);
    });
  }
  Object.keys(obj).forEach(element => {
    const property = obj[element];
    if (isArray(property) || isObject(property)) {
      this.removeProperty(property, name);
    }
  });
}


/*
  prepareRenderTopic( topic: any)
  {
    topic.tmpChannels = [];
    topic.channels.forEach(element => {
      const channel = this.channels.find( channel => channel.id === element.id);
      if (channel)
      {
        topic.tmpChannels.push(channel);
      }
    });
  }
*/
ngOnInit() {
  this.API.ListChannelSources().then(results => {
    this.removeProperty(results.items, '__typename');
    this.channels = results.items;
    this.loadTopics();

    console.log(results);
  });


}

loadTopics() {
  this.API.ListTopics().then(results => {
    this.topics = results.items;
    // this.removeProperty( this.topics, '__typename');
    this.topics.forEach(topic => this.prepareTopic(topic));
    console.log(results);
  }).catch(reason => {
    console.log(reason);
  });
}

addTopic(name: string) {
  const input: CreateTopicInput = {
    name: name,
    isActive: true,
    keys: [],
    language: DEFAULT_TOPIC_LANGUAGE,
    channels: []
  };
  this.prepareTopic(input);
  this.topics.splice(0, 0, input);
  this['newTopic'] = '';
}

/*addChannel( topic: CreateTopicInput, channel: any )
{
  if (topic.channels.find(item => item.id === channel.id) === undefined)
    {
      topic.channels.push(channel);
    }
    this.updateTopicChannels(topic);
}*/
/*
  setChannel(channel : any ){
    channel.selected = !channel.selected;
  }*/

prepareTopic(topic: CreateTopicInput) {
  topic['tmpLanguage'] = new FormControl('');
  topic['tmpLanguage'].setValue(topic.language);
  topic['tmpChannels'] = this.channels.map(channel => {
    if (topic.channels.find(item => item === channel.id)) {
      return {
        ...
        channel,
        selected: true
      };
    } else {
      return {
        ...
        channel,
        selected: false
      };
    }
  });
}

addKeyword(topic: CreateTopicInput, keyword: string) {
  topic.keys.push({ name: keyword, terms: [] });
}

addTerm(terms: [string], name: string) {
  terms.push(name);
}

removeTerm(terms: [string], index: number) {
  terms.splice(index, 1);
}

removeKeyword(topic: any, index: string) {
  topic.keys.splice(index, 1);
}

removeTopic(topic: any) {
  const id = topic.id;
  const index = this.topics.indexOf(topic);
  this.topics.splice(index, 1);
  this.API.DeleteTopic({ id }).then(response => {
    console.log(response);
  }).catch(reason => {
    console.log('ERROR: ', reason);
  });
}

saveTopic(input: any) {
  delete input.tmpKey;
  delete input.tmpTerm;
  input.language = input['tmpLanguage'].value;
  if (!input.id) {
    const update: CreateTopicInput = {
      ...input,
      id: input.id,
      channels: input.tmpChannels.filter(channel => channel.selected).map(channel => channel.id)
    };
    delete update['tmpChannels'];
    delete update['tmpLanguage'];

    this.API.CreateTopic(update).then(response => {
      console.log(response);
      this.loadTopics();
    }).catch(reason => {
      console.log('ERROR: ', reason.errors);
    });
  } else {
    const update: UpdateTopicInput = {
      ...input,
      id: input.id,
      channels: input.tmpChannels.filter(channel => channel.selected).map(channel => channel.id)
    };
    delete update['tmpChannels'];
    delete update['tmpLanguage'];
    this.removeProperty(update, '__typename');

    this.API.UpdateTopic(update).then(response => {

      this.loadTopics();
    }).catch(error => {
      console.log(error.errors[0].message);
    });
  }
}
}
