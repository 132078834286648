
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'text-decorator',
  templateUrl: './text-decorator.component.html',
  styleUrls: ['./text-decorator.component.css']
})
export class TextDecoratorComponent implements OnInit {

  @Input('data') data: any;
  
  words : {word: String, className: String} [] = []

  constructor() { }

  ngOnInit() {

    let tmp = this.data.text.split(' ');

    this.words = tmp.map( (word, index) => {
        //let key = this.data.id + ' ' + index;
        var match = this.data.sentiments.find( sentiment => {return word.indexOf(sentiment.stem) != -1 } );
        
        let result =
        {
          word: word,
          className: ''
        }
        if (match === undefined)
        {

        }else if (match.sentiment > 0)
        {
            result.className='positiveWordHighlight';
        }
        else
        {
          result.className='negativeWordHighlight';
        }
        return result;

    });
  }

}
