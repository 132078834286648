import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { NavbarComponent } from './navbar/navbar.component';
import { TextAnalysisComponent } from './text-analysis/text-analysis.component';
import { TextDecoratorComponent } from './text-decorator/text-decorator.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MytopicsComponent } from './mytopics/mytopics.component';
import { MeasuresenseComponent } from './measuresense/measuresense.component';
import { DatasourcesComponent } from './datasources/datasources.component';
import { PricingComponent } from './pricing/pricing.component';
import { FooterComponent } from './footer/footer.component';
import { PerciveComponent } from './percive/percive.component';
import { NgInitDirective } from './init-directive';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  MatButtonModule,
  MatMenuModule,
  MatCheckboxModule,
  MatSlideToggleModule,
  MatFormFieldModule,
  MatAutocompleteModule,
  MatOptionModule,
  MatInputModule,
  MatTooltipModule} from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    MainMenuComponent,
    NavbarComponent,
    TextAnalysisComponent,
    TextDecoratorComponent,
    DashboardComponent,
    MytopicsComponent,
    MeasuresenseComponent,
    DatasourcesComponent,
    PricingComponent,
    FooterComponent,
    PerciveComponent,
    NgInitDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    MatMenuModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatOptionModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTooltipModule,
    RouterModule.forRoot(
      [
        { path: 'login', component: SignInComponent },
        { path: 'menu', component: MainMenuComponent },
        { path: 'datasources', component: DatasourcesComponent },
        { path: 'dashboard', component: DashboardComponent },
        { path: 'mytopics', component: MytopicsComponent },
        { path: 'measuresense', component: MeasuresenseComponent },
        { path: 'pricing', component: PricingComponent },
        { path: 'menu', component: MainMenuComponent },
        { path: 'percive', component: PerciveComponent },
       // { path: '', component: MainMenuComponent },
        { path: '', component: PerciveComponent },
      ],
      {
        anchorScrolling: 'enabled'
      }
      )
      ],
      exports: [
/*        MatButtonModule,
        MatCheckboxModule,
        MatMenuModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatOptionModule,
        ReactiveFormsModule*/
      ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
