/// <reference types="aws-sdk" />
import { Component } from '@angular/core';
import Amplify, { Auth } from 'aws-amplify';
import aws_exports from '../aws-exports';


// import entire SDK
// import AWS object without services
// https://aws-amplify.github.io/docs/js/authentication
// https://github.com/aws/aws-sdk-js/issues/1944
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'percive';


  // Add 'aws-amplify' library into your application

  init = function()
  {

  let username = 'tero';

  let password = 'salsaa';

//  Amplify.configure(aws_exports);



  Amplify.configure({
    Auth: {

        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: 'eu-central-1:5930b9c5-3886-4674-a10c-654ae59bdb54',
        // REQUIRED - Amazon Cognito Region
        region: 'eu-central-1',

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
        // Required only if it's different from Amazon Cognito Region
        identityPoolRegion: 'eu-central-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        //userPoolId: 'eu-central-1_TuF2WBINP"',
        userPoolId: 'eu-central-1_fBOOwoq0r', //'eu-central-1_fBOOwoq0r',
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        //userPoolWebClientId: '1mpjifcccuko9kmsda2odinev',
        // When client is created, you need to UNCHECK the "generate client secret"
        userPoolWebClientId: '3huq21uhsreu5bg0ih451pte0t', //'3huq21uhsreu5bg0ih451pte0t',
        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,


        /*
        // OPTIONAL - Configuration for cookie storage
        cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
            domain: '.yourdomain.com',
        // OPTIONAL - Cookie path
            path: '/',
        // OPTIONAL - Cookie expiration in days
            expires: 365,
        // OPTIONAL - Cookie secure flag
            secure: true
        },*/

        // OPTIONAL - customized storage object
       // storage: new MyStorage(),
        
        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_PASSWORD_AUTH'
    },

    API: {
        /*endpoints: [
            {
                name: 'MyFunction',
                endpoint: 'https://lambda.eu-west-1.amazonaws.com/2015-03-31/functions/MyFunction/invocations',
                service: 'lambda',
                region: 'eu-central-1',
            },
        ],*/
     
        aws_appsync_graphqlEndpoint: "https://djhc4gtpvngy5ikcslr2pylgna.appsync-api.eu-central-1.amazonaws.com/graphql",
        aws_appsync_region: "eu-central-1",
        aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    },
});



  }

  ngOnInit()
  {
    this.init();
  }

}
 
