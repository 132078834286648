import { APIService } from '../API.service';
import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent implements OnInit {

  constructor(private userService: UserService, 
    private router: Router,
    private APIservice: APIService) { 
    /*if (!userService.user())
    {
      this.router.navigate(['login']); 
    }*/
  }

  ngOnInit() {
  }

  

}
